import React from "react";

const Footer = () => {
	const date = new Date();
	const dateYear = date.getFullYear();

	return (
		<footer className="footer__wrap">
			<div className="container">
				<div className="footer-law">
					<h2>Conforme LEI Nº 8390, DE 05 DE JUNHO DE 2001</h2>
					<h3>
						ESTABELECE A OBRIGATORIEDADE AOS BARES, RESTAURANTES E
						SIMILARES DE OFERECER CARDÁPIO EM FORMATO ACESSÍVEL ÀS
						PESSOAS COM DEFICIÊNCIA VISUAL. (Redação dada pela Lei
						nº 12.854/2017)
					</h3>
					<p>
						Prefeito EDINHO ARAUJO, Prefeito Municipal de São José
						do Rio Preto, Estado de São Paulo, usando das
						atribuições que me são conferidas por Lei. FAÇO SABER
						que a Câmara Municipal aprovou e eu sanciono e promulgo
						a seguinte Lei:
					</p>
					<ul>
						<li>
							Art. 1º Ficam todos os estabelecimentos localizados
							neste Município, que comercializem refeições para
							consumo no local, obrigados a manter disponíveis aos
							consumidores cardápios em formato acessível para
							pessoas com deficiência visual.
						</li>
						<li>
							Parágrafo único. Considera-se formato acessível todo
							aquele em que qualquer pessoa com deficiência visual
							possa ter acesso ao conteúdo do cardápio de forma
							autônoma e independente, por vias tecnológicas
							"QRCode" e/ou impressão em Braille. (Redação dada
							pela Lei nº 12.854/2017)
						</li>
						<li>
							Art. 2º Fica a cargo da Prefeitura Municipal,
							através do seu órgão competente, o cumprimento e a
							fiscalização desta Lei.
						</li>
						<li>
							Art. 3º A concessão ou renovação de Alvarás de
							funcionamento só será fornecido aos estabelecimentos
							que se adequarem a presente Lei. Parágrafo Único -
							Os estabelecimentos em funcionamento deverão
							adequar-se à exigência da Lei no prazo de 60
							(sessenta) dias.
						</li>
						<li>Art. 4º VETADO</li>
						<li>
							Art. 5º Fica a cargo do Poder Executivo a
							regulamentação desta Lei no prazo de 30 (trinta)
							dias, a partir da data de sua vigência.
						</li>
						<li>
							Art. 6º As despesas com a execução da presente Lei
							correrão por conta de verbas próprias do orçamento,
							suplementadas se necessário.
						</li>
						<li>
							Art. 7º Esta Lei entra em vigor na data de sua
							publicação, revogadas as disposições em contrário.
						</li>
					</ul>
					<p>
						Prefeitura Municipal de São José do Rio Preto, 05 de
						junho de 2001
					</p>
					<p>
						EDINHO ARAUJO
						<br />
						Prefeito
					</p>
					<p>Autógrafo nº 8948</p>
					<p>Proj. lei 075/2001, do Ver. Luiz Alberto Andaló</p>
				</div>

				<hr />

				<div className="footer-copyright">
					<p>Super Muffato - É bem feito. É bem você!</p>
					<p>© {dateYear} - Todos os direitos reservados.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
