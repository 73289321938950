import React from "react";
import { Route, Switch } from "react-router-dom";

import { StoreStorage } from "./contexts/StoreContext";

import HomePage from "./pages/Home";
import PageNotFound from "./pages/NotFound";

const Routers = () => {
	return (
		<StoreStorage>
			<Switch>
				<Route
					path={`${process.env.PUBLIC_URL}/`}
					exact={true}
					component={HomePage}
				/>
				<Route path="*" exact={true} component={PageNotFound} />
			</Switch>
		</StoreStorage>
	);
};

export default Routers;
