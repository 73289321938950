import React from "react";

import { StoreContext } from "contexts/StoreContext";

const Header = () => {
	const accessibility = {
		font_plus: "Aumentar tamanho da fonte",
		font_minus: "Diminuir tamanho da fonte",
		font_normal: "Voltar ao tamanho original da fonte",
		contrast: "Mudar contraste",
		dyslexic: "Alterar para fonte disléxica",
	};

	const {
		handleFontSizePlus,
		handleFontSizeMinus,
		handleFontSizeDefault,
		handleFontDyslexic,
		handleAddClassContrast,
	} = React.useContext(StoreContext);

	return (
		<header className="header__wrap">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-4">
						<div className="header-logo">
							<img
								src="images/logo_muffato_branco.png"
								className=""
								alt="Super Muffato"
							/>
						</div>
					</div>

					<div className="col-12 offset-sm-4 col-sm-4">
						<div className="header-accessibility">
							<p aria-label="Opções de acessibilidade">
								Acessibilidade
							</p>

							<ul>
								<li>
									<button
										className="btn"
										aria-label={accessibility.font_minus}
										title={accessibility.font_minus}
										onClick={handleFontSizeMinus}
									>
										A-
									</button>
								</li>
								<li>
									<button
										className="btn"
										aria-label={accessibility.font_plus}
										title={accessibility.font_plus}
										onClick={handleFontSizePlus}
									>
										A+
									</button>
								</li>
								<li>
									<button
										className="btn"
										aria-label={accessibility.font_normal}
										title={accessibility.font_normal}
										onClick={handleFontSizeDefault}
									>
										A
									</button>
								</li>
								<li>
									<button
										className="btn"
										aria-label={accessibility.dyslexic}
										title={accessibility.dyslexic}
										onClick={handleFontDyslexic}
									>
										A
									</button>
								</li>
								<li>
									<button
										className="btn btn-contrast"
										aria-label={accessibility.contrast}
										title={accessibility.contrast}
										onClick={handleAddClassContrast}
									>
										<i
											className="fa fa-adjust"
											aria-hidden="true"
										></i>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
