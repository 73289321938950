import React from "react";

//import { Container } from '../../styles';
import LayoutBase from "components/Layout/LayoutBase";

const PageNotFound = () => {
	return (
		<LayoutBase>
			<>
				<h2 className="notfound__title">
					<sup>Erro</sup>404
				</h2>
				<h3 className="notfound__subtitle">
					Não encontramos a página solicitada.
				</h3>
			</>
		</LayoutBase>
	);
};

export default PageNotFound;
