import React from "react";
import { Redirect } from "react-router-dom";
import useGoogleSheets from "use-google-sheets";
import useQuery from "hooks/useQuery";

import { treatmentObjectKeys } from "helpers/ObjectTreatment";

import LayoutBase from "components/Layout/LayoutBase";

import MenuRestaurant from "components/Partials/MenuRestaurant";
import MenuDelicatessen from "components/Partials/MenuDelicatessen";

import "styles/ListMenu.scss";

const PageHome = () => {
	const [newData, setNewData] = React.useState([]);
	const query = useQuery();

	const { data, loading, error } = useGoogleSheets({
		apiKey: "AIzaSyCeEFlDQY5Bl4MiGZkB-UC-DRBSEYnnsSE",
		sheetId: "1cxEnTNq7I4j8W7vMueMonJ_1x4Jjre62ulcrLvMKU10",
	});

	React.useEffect(() => {
		if (data[0]?.data) {
			let arrData = data[0].data;
			let arrNewData = [];
			treatmentObjectKeys(arrData);

			if (arrData.length !== 0) {
				if (query.get("type") === "restaurante") {
					arrData.forEach((item) => {
						if (
							item.qual_o_tipo_de_cardapio ===
							"Restaurante Buffet Grill"
						) {
							arrNewData.push(item);
						}
					});
				}

				if (query.get("type") === "padaria") {
					arrData.forEach((item) => {
						if (item.qual_o_tipo_de_cardapio === "Delicatessen") {
							arrNewData.push(item);
						}
					});
				}
			}

			setNewData(arrNewData);
		}
	}, [data]);

	if (query.get("id") === null || query.get("type") === null) {
		return <Redirect to={"?id=108&type=restaurante"} />;
	}

	if (loading) {
		return <LayoutBase>Carregando...</LayoutBase>;
	}

	if (error) {
		return <LayoutBase>Erro!</LayoutBase>;
	}

	return (
		<LayoutBase>
			<div className="list-menu">
				{query.get("type") === "restaurante" && (
					<MenuRestaurant
						data={newData}
						id={Number(query.get("id"))}
					/>
				)}

				{query.get("type") === "padaria" && (
					<MenuDelicatessen
						data={newData}
						id={Number(query.get("id"))}
					/>
				)}
			</div>
		</LayoutBase>
	);
};

export default PageHome;
