import React from "react";

import Header from "./Header";
import Footer from "./Footer";

import "styles/Dyslexic.scss";
import "styles/Contrast.scss";
import "styles/Layout.scss";

const LayoutBse = ({ children }) => {
	return (
		<>
			<Header />

			<main className="main__wrap">
				<div className="container">{children}</div>
			</main>

			<Footer />
		</>
	);
};

export default LayoutBse;
