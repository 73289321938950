import React from "react";

export const StoreContext = React.createContext(null);

export const StoreStorage = ({ children }) => {
	const [fontSize, setFontSize] = React.useState(16);

	React.useEffect(() => {
		document.body.style.fontSize = `${fontSize / 16}rem`;
	}, [fontSize]);

	const handleFontSizePlus = () => setFontSize(fontSize + 2);

	const handleFontSizeMinus = () => setFontSize(fontSize - 2);

	const handleFontSizeDefault = () => setFontSize(16);

	const handleFontDyslexic = () => document.body.classList.toggle("dyslexic");

	const handleAddClassContrast = () => {
		document.body.classList.toggle("contrast");
		document.querySelector(".btn.btn-contrast").classList.toggle("rotate");
	};

	return (
		<StoreContext.Provider
			value={{
				handleFontSizePlus,
				handleFontSizeMinus,
				handleFontSizeDefault,
				handleFontDyslexic,
				handleAddClassContrast,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};
