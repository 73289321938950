import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { getIdFilial } from "helpers/ObjectTreatment";

const MenuRestaurant = ({ data, id }) => {
	console.log(data);
	return (
		<>
			{data
				.filter(
					(d) =>
						moment(d.data_do_cardapio, "DD/MM/YYYY").isSame(
							moment(),
							"day"
						) && getIdFilial(d.escolha_uma_filial) === id
				)
				.map((item, indexItem) => (
					<div
						key={item.carimbo_de_datahora}
						className="list-menu__block"
					>
						<h1>{item.escolha_uma_filial}</h1>
						<h2>Cardápio do dia {item.data_do_cardapio}</h2>
						<hr style={{ margin: "2rem 0" }} />
						<ul key={indexItem + moment().unix()}>
							<li>
								<h2>Pratos quentes:</h2>
								<p>{item.pratos_quentes}</p>
							</li>
							<li>
								<h2>Pratos frios:</h2>
								<p>{item.pratos_frios}</p>
							</li>
							<li>
								<h2>Sobremesa:</h2>
								<p>{item.sobremesa}</p>
							</li>
							<li>
								<h2>Bebidas:</h2>
								<p>{item.bebidas}</p>
							</li>
							<li>
								<h2>Preço por quilo:</h2>
								<p>{item.preco_kg}</p>
							</li>
							<li>
								<h2>
									Preço por quilo
									<small>(cliente ClubeFato)</small>:
								</h2>
								<p>{item.preco_kg_cliente_clubeFato}</p>
							</li>
						</ul>
					</div>
				))}
		</>
	);
};

MenuRestaurant.propTypes = {
	data: PropTypes.array,
	id: PropTypes.number,
};

export default MenuRestaurant;
