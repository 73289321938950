import React from "react";
import PropTypes from "prop-types";

import { getIdFilial } from "helpers/ObjectTreatment";

const MenuRestaurant = ({ data, id }) => {
	const [newData] = React.useState(
		data.filter((d) => getIdFilial(d.escolha_uma_filial) === id)
	);

	return (
		<>
			{newData.map(
				(item, indexItem) =>
					indexItem + 1 === newData.length && (
						<div
							key={item.carimbo_de_datahora}
							className="list-menu__block list-menu__delicatessen"
						>
							{console.log(item)}
							<h1>{item.escolha_uma_filial}</h1>
							{/* <h2>Cardápio do dia {item.data_do_cardapio}</h2> */}
							<hr style={{ margin: "2rem 0" }} />
							<h2>Café</h2>
							<ul className="delicatessen">
								<li>
									<h3>Expresso:</h3>
									<div className="line"></div>
									<p>{item.cafe_expresso}</p>
								</li>
								<li>
									<h3>Americano:</h3>
									<div className="line"></div>
									<p>{item.cafe_americano}</p>
								</li>
								<li>
									<h3>Cappuccino Tradicional:</h3>
									<div className="line"></div>
									<p>{item.cappuccino_tradicional}</p>
								</li>
								<li>
									<h3>Café com leite:</h3>
									<div className="line"></div>
									<p>{item.cafe_com_leite}</p>
								</li>
								<li>
									<h3>Nescau:</h3>
									<div className="line"></div>
									<p>{item.nescau}</p>
								</li>
								<li>
									<h3>Cappuccino Alpino:</h3>
									<div className="line"></div>
									<p>{item.cappuccino_alpino}</p>
								</li>
								<li>
									<h3>Achocolatado Alpino:</h3>
									<div className="line"></div>
									<p>{item.achocolatado_alpino}</p>
								</li>
							</ul>

							<h2>Lanches</h2>
							<ul className="delicatessen">
								<li>
									<h3>Pão na chapa:</h3>
									<div className="line"></div>
									<p>{item.pao_na_chapa}</p>
								</li>
								<li>
									<h3>Misto quente:</h3>
									<div className="line"></div>
									<p>{item.misto_quente}</p>
								</li>
								<li>
									<h3>Queijo quente:</h3>
									<div className="line"></div>
									<p>{item.queijo_quente}</p>
								</li>
							</ul>

							<h2>Pastéis</h2>
							<ul className="delicatessen">
								<li>
									<h3>Carne:</h3>
									<div className="line"></div>
									<p>{item.pastel_de_carne}</p>
								</li>
								<li>
									<h3>Carne com queijo:</h3>
									<div className="line"></div>
									<p>{item.pastel_de_carne_com_queijo}</p>
								</li>
								<li>
									<h3>Frango com requeijão:</h3>
									<div className="line"></div>
									<p>{item.pastel_de_frango_com_requeijao}</p>
								</li>
								<li>
									<h3>Moda da casa:</h3>
									<div className="line"></div>
									<p>{item.pastel_a_moda_da_casa}</p>
								</li>
								<li>
									<h3>Palmito:</h3>
									<div className="line"></div>
									<p>{item.pastel_de_palmito}</p>
								</li>
								<li>
									<h3>Queijo:</h3>
									<div className="line"></div>
									<p>{item.pastel_de_queijo}</p>
								</li>
								<li>
									<h3>Pizza:</h3>
									<div className="line"></div>
									<p>{item.pastel_de_pizza}</p>
								</li>
							</ul>
						</div>
					)
			)}
		</>
	);
};

MenuRestaurant.propTypes = {
	data: PropTypes.array,
	id: PropTypes.number,
};

export default MenuRestaurant;
