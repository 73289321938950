import React from "react";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes";

import "./styles/App.scss";
import "./styles/Grid.scss";

const App = () => {
	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};

export default App;
