export const treatmentObjectKeys = (object) => {
	return Object.keys(object).forEach(function (key) {
		var newKey = key
			.toLowerCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.replaceAll("(", "")
			.replaceAll(")", "")
			.replaceAll("/", "")
			.replaceAll("-", "")
			.replace(/\s+/g, "_");

		if (object[key] && typeof object[key] === "object") {
			treatmentObjectKeys(object[key]);
		}

		if (key !== newKey) {
			object[newKey] = object[key];
			delete object[key];
		}
	});
};

export const getIdFilial = (text) =>
	Number(text.split("|")[0].replaceAll(" ", ""));
